@import './variables.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    @apply antialiased;
    @apply text-dark-950;
    font-feature-settings:
      'pnum' on,
      'lnum' on;
  }
  body.no-scroll {
    overflow: hidden;
  }

  h1 {
    @apply text-2xl;
    @apply text-black;
    @apply font-medium;
  }
  h2 {
    @apply text-xl;
    @apply text-black;
    @apply font-medium;
  }
  h3 {
    @apply text-lg;
    @apply text-black;
    @apply font-medium;
  }

  :focus-visible {
    outline: var(--webkit-focus-ring-color) auto 1px;
  }

  input:focus {
    --tw-ring-color: var(--webkit-focus-ring-color) !important;
    border-color: var(--webkit-focus-ring-color) !important;
  }

  input:-internal-autofill-selected {
    @apply bg-secondary-100 !important;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  [contenteditable='true']:has(br):before,
  [contenteditable='true']:empty:before {
    content: attr(placeholder);
    @apply text-placeholder;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  .has-sidebar .intercom-app > div + div,
  .has-sidebar .intercom-launcher {
    right: 424px;
    display: none;
  }

  .has-sidebar .intercom-app:not(:hover) > div + div,
  .has-sidebar .intercom-lightweight-app:not(:hover) .intercom-launcher {
    filter: grayscale(1);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100vh;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.15);
}

.userflowjs-modal-backdrop {
  backdrop-filter: blur(4px);
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
}

.Toastify__toast {
  min-height: 42px !important;
}

.Toastify__toast-body {
  padding: 6px 6px 8px !important;
}
