:root {
  --color-accent-1: theme('colors.accent.1');
  --color-accent-2: theme('colors.accent.2');
  --color-accent-3: theme('colors.accent.3');
  --color-accent-4: theme('colors.accent.4');
  --color-accent-5: theme('colors.accent.5');
  --color-accent-6: theme('colors.accent.6');
  --color-accent-7: theme('colors.accent.7');
  --color-accent-8: theme('colors.accent.8');
  --color-accent-9: theme('colors.accent.9');
  --color-accent-10: theme('colors.accent.10');

  --color-gray-100: theme('colors.gray.100');
  --color-gray-200: theme('colors.gray.200');
  --color-gray-300: theme('colors.gray.300');
  --color-gray-400: theme('colors.gray.400');
  --color-gray-500: theme('colors.gray.500');
  --color-gray-600: theme('colors.gray.600');
  --color-gray-700: theme('colors.gray.700');
  --color-gray-800: theme('colors.gray.800');
  --color-loader: theme('colors.loader');

  --webkit-focus-ring-color: theme('colors.primary.500');
}
